import React from 'react';
import InstrumentImage from './instrumentImage';
import play from '../icons/play.png';

const divContainer = {
  display: 'flex',
  alignItems: 'center'
}

const buttonStyle = {
  height: '50px',
  width: '50px',
  margin: '0px 0px 0px 20px',
  cursor: 'pointer'
}

const divStyle = {
  position: 'relative',
  margin: '10px 10px 0px 20px',
  padding: '0px 0px 0px 10px',
  width: '500px',
  border: '1px solid black',
  borderRadius: '10px'
}

const divStylePlayedToday = {
  position: 'relative',
  margin: '10px 10px 0px 20px',
  padding: '0px 0px 0px 10px',
  width: '500px',
  border: '1px solid black',
  borderRadius: '10px',
  backgroundColor: 'grey'
}

const idStyle = {
  position: 'absolute',
  display: 'inline-block',
  margin:'0px 10px 0px 0px',
  right: '0'
}

const dateStyle = {
  position: 'absolute',
  display: 'inline-block',
  margin:'0px 10px 0px 0px',
  right: '0',
  bottom: '0'
}

export default function PlaylistSong({song, todayDate, onPlayed}) {

  function handlePlayed(i) {
    onPlayed(i)
  }

  function getDate() {
    if(song.lastPlayed !== undefined) {
      const date = new Date(song.lastPlayed);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const formattedDate = new Intl.DateTimeFormat('fr-BE', options).format(date);
      return formattedDate
    } else {
      return "never played"
    }
    
  }

  return (
    <div style = {divContainer}>
      {song.lastPlayed >= todayDate? 
        <div style={divStylePlayedToday}>
        <div style={idStyle}>{song.id}</div>
        <div style={dateStyle}>{getDate()}</div>
        <b>{song.artist.name}</b> | {song.title}
        <br/>
        Tuning : {song.tuning.name} | {song.style.name} | {song.instrument.map(element => {
          return (<InstrumentImage id={element.id}/>)
        })}
        <br/>
      </div> 
      : 
      <div style={divStyle}>
      <div style={idStyle}>{song.id}</div>
      <div style={dateStyle}>{getDate()}</div>
      <b>{song.artist.name}</b> | {song.title}
      <br/>
      Tuning : {song.tuning.name} | {song.style.name} | {song.instrument.map(element => {
        return (<InstrumentImage id={element.id}/>)
      })}
      <br/>
    </div>
    }
    <img src={play} style={buttonStyle} onClick={() => handlePlayed(song.id)} alt="played"/>
    </div>
  );
}