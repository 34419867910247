import React from 'react';
import Button from './button';

const divStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0px 20px 20px'
  };

export default function ButtonList({buttonListObject, onClick, updateList}) {
  function handleClick(i) {
    onClick(i, buttonListObject, updateList)
  }

  return (
    <div style={divStyle}>
        {buttonListObject.length > 0 && buttonListObject.map((object, i) => {
            return (<Button 
              name={object.name}
              state={object.state}
              onClick={() => handleClick(i)}/>) 
        })}
    </div>
  );
}