import React, {useState} from 'react'

import { addArtist } from '../services/firestoreHelper.js';

import toUpperCase from '../utils/strings.js'

export default function AddArtistScreen({artists, onAdd}) {
    const [artistName, setArtistName] = useState("");

    function onNameChange(name) {
        setArtistName(name)
    }

    function addArtistToFirebase() {
        const id = parseInt(artists[0].id) + 1;
        const artist = {name: toUpperCase(artistName)}

        artists.unshift(artist)

        onAdd(artists)
        setArtistName("")

        const putArtist = async () => {
            await addArtist(id.toString(), artist)
        }

        putArtist()
     }

    return (
        <div>
              <input 
                  placeholder="Artist Name"
                  value={artistName} 
                  onChange={evt => onNameChange(evt.target.value) }/>
              <div onClick={() => addArtistToFirebase()}>Valider</div>
              {artists.length > 0 && artists.map((artist, i) => {
                  return (<p>{artist.name}</p>) 
              })}
        </div>
    );
}