import React from 'react';

import electric_guitar from '../icons/electric_guitar.png';
import acoustic_guitar from '../icons/acoustic_guitar.png';
import bass_guitar from '../icons/bass_guitar.png';
import classical_guitar from '../icons/classical_guitar.png';
import drum from '../icons/drum.png';
import seven_string_guitar from '../icons/7_string_guitar.png';
import not_found from '../icons/not_found.png'

const imageStyle = {
    width: '35px',
    height: '35px',
    margin: '0px 0px 5px 10px'
}

export default function InstrumentImage({id}) {

    function getURI() {
        var uri = ""
        switch(id) {
            case "01":
                uri = electric_guitar
                break;
            case "02":
                uri = acoustic_guitar
                break;
            case "03":
                uri = classical_guitar
                break;
            case "04":
                uri = bass_guitar
                break;
            case "05":
                uri = drum
                break;
            case "06":
                uri = seven_string_guitar
                break
            default:
                uri = not_found
                break;
        }

        return uri
    }

    return (<img style={imageStyle} src={getURI()} alt={getURI()}/>)
  }