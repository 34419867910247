import axios from "axios";

import Cookies from 'universal-cookie';

import { Buffer } from 'buffer';

const clientSecret = "cac6d1098cc646fd81e3de721249c519"
const clientId = "0ac3bbd102ce4118a84f6eea34d6c6f1"

const redirectUrl = "https://milist-d7023.web.app/spotifyRedirection"
const scope = "playlist-modify-public playlist-modify-private"

const auth_token = Buffer.from(`${clientId}:${clientSecret}`, 'utf-8').toString('base64')

const headers = {
    'Authorization': `Basic ${auth_token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
}

const args = new URLSearchParams(window.location.search);
const code = args.get('code');

const cookies = new Cookies();

export const getSpotifyAuth = async () => {
    if (code) {
        await getTokenAuth(code);
      
        const url = new URL(window.location.href);
        url.searchParams.delete("code");
      
        const updatedUrl = url.search ? url.href : url.href.replace('?', '');
        window.history.replaceState({}, document.title, updatedUrl);
      }
}

export const redirectToSpotifyAuthorize = async () => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomValues = crypto.getRandomValues(new Uint8Array(64));
    const randomString = randomValues.reduce((acc, x) => acc + possible[x % possible.length], "");
  
    const code_verifier = randomString;
    const data = new TextEncoder().encode(code_verifier);
    const hashed = await crypto.subtle.digest('SHA-256', data);
  
    const code_challenge_base64 = btoa(String.fromCharCode(...new Uint8Array(hashed)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  
    window.localStorage.setItem('code_verifier', code_verifier);
  
    const authUrl = new URL("https://accounts.spotify.com/authorize")
    const params = {
      response_type: 'code',
      client_id: clientId,
      scope: scope,
      code_challenge_method: 'S256',
      code_challenge: code_challenge_base64,
      redirect_uri: redirectUrl,
    };
  
    authUrl.search = new URLSearchParams(params).toString();
    window.location.href = authUrl.toString(); 
}

export const getToken = async () => {
    console.log("from nothing")
    axios.post("https://accounts.spotify.com/api/token", "grant_type=client_credentials", {
        headers: headers
    }).then((response) => {
        cookies.set('access_token', response.data.access_token, { path: '/', maxAge: response.data.expires_in });
        return response.data.access_token
    }, (error) => {
        alert("A spotify error occure : " + error.response.data.error.message)
    });
}

export const getTokenAuth = async(code) => {
    console.log("from auth")

    const code_verifier = window.localStorage.getItem('code_verifier');
  
    const response = await fetch("https://accounts.spotify.com/api/token", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: clientId,
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: redirectUrl,
        code_verifier: code_verifier,
      }),
    });

    const { access_token, refresh_token, expires_in } = await response.json();
  
    cookies.set('access_token', access_token, { path: '/', maxAge: expires_in });
  }

export const getSpotifyURI = async(artist, songName) => {
    return axios.get("https://api.spotify.com/v1/search", {
        headers: {
            'Authorization': `Bearer ${cookies.get("access_token")}`,
        },
        params: {
            'q': `"${songName}" artist:"${artist}"`,
            'type' : 'track',
            'market' : 'BE'
        }
    }).then((response) => {
        let uri = ""
        for(let i = 0; i < response.data.tracks.items.length; i++) {
            var track = response.data.tracks.items[i]
            if(track.artists[0].name.toLowerCase() === artist.toLowerCase() && track.name.toLowerCase() === songName.toLowerCase()) {
                uri = response.data.tracks.items[i].uri
                break;
            }
        }

        return uri
    }, (error) => {
        if(error.response.data.error.status === 401) {
            getToken()
            getSpotifyURI()
        } else {
            alert("A spotify error occure : " + error.response.data.error.message)
        }
    })
}

export const addSongToPlaylist = async (uri) => {
    axios.post("https://api.spotify.com/v1/playlists/6rUoX8oXxDUPrFTNL7P4QJ/tracks", 
    {
        uris: uri
    },{
        headers: {
            'Authorization': `Bearer ${cookies.get("access_token")}`
        }
    }).then((response) => {
        return response.data.access_token
    }, (error) => {
        alert("A spotify error occure : " + error)
    });
}

export const clearPlaylist = async () => {
    try {
        await axios.put(`https://api.spotify.com/v1/playlists/6rUoX8oXxDUPrFTNL7P4QJ/tracks`, {uris: []}, {
            headers: {
                'Authorization': `Bearer ${cookies.get("access_token")}`,
                'Content-Type': 'application/json'
            }
        });
        return true; 
    } catch (error) {
        console.error("Une erreur s'est produite lors de la suppression des éléments de la playlist : ", error);
        return false;
    }
};