import React from 'react';

const divStyle = {
  margin: '15px 15px 0px 0px', 
  width: '100px',
  cursor: 'pointer'
}

const selectedButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '105px',
  height: '55px',
  backgroundColor: 'orange',
  border: '1px solid black',
  borderRadius: '10px'
}

const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '105px',
  height: '55px',
  border: '1px solid black',
  borderRadius: '10px'
}

export default function Button({state, name, onClick}) {

  return (
    <div style={divStyle}>
      {state ? 
        <div style={selectedButtonStyle} onClick={() => onClick()}>
          {name}
        </div> 
        : 
        <div style={buttonStyle} onClick={() => onClick()}>
          {name}
        </div>}
    </div>
  );
}