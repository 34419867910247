import React, {useEffect, useState} from 'react'

import ButtonList from '../component/buttonList.js';
import TextInput from "../component/textInput.js";
import Error from "../object/error.js";
import toUpperCase from '../utils/strings.js';
import {getSpotifyURI} from '../services/spotifyAPI.js'

import { addSongToFirestore, deleteSongToFirebase, findAll } from '../services/firestoreHelper.js';
import SearchableDropdown from '../component/searchableDropdown.js';
import AddedSong from '../component/addedSong.js';

import bigIcon from '../icons/big_icon.png';

const validateButtonStyle = {
    position: 'absolute',
    left: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0px 0px 0px 20px',
    backgroundColor: 'green',
    width: '275px',
    height: '102px',
    border: '1px solid black',
    borderRadius: '10px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '20px',
    cursor: 'pointer'
}

const lineSeparatorStyle = {
    margin:'25px 20px 0px 20px',
    border: 'none',
    backgroundColor: 'black',
    height: '3px',
    opacity: '1'
}

const songNumberStyle = {
    position: 'absolute',
    display: 'inline-block',
    margin: '0px 20px 0px 0px',
    right: '0'
}

const topMenu = {
    //position: 'sticky',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: '100',
    backgroundColor: 'white'
}

const contentStyle = {
    flexGrow: '1',
    boxSizing: 'border-box'
}

const containerStyle = {
    display: 'flex',
    flexDirection: 'column'
}

const iconStyle = {
    display: 'block',
    margin: '10px auto 10px auto',
    height: '50px'
}


export default function AddSongScreen({data, onAdd}) {
    const [songCount, setSongCount] = useState(0);
    const [inputSongName, setInputSongName] = useState("");
    const [selectedArtist, setSelectedArtist] = useState({id: 0, name: ""});
    const [selectedId, setSelectedId] = useState()

    const [blockEvent, setBlockEvent] = useState(false)

    const [tunings, setTunings] = useState({})
    const [instruments, setInstruments] = useState({})
    const [styles, setStyles] = useState({})


    useEffect(() => {
        document.addEventListener("keyup", keyPressed.bind(this), false);

        if(tunings.length === undefined) {
            initData()
        }
     });

     function initData() {
        setSongCount(parseInt(data.songs.length))
        setTunings(data.tunings)
        setStyles(data.styles)
        setInstruments(data.instruments)
     }

    function getSelectedInstrument() {
        var instrument = []
        instruments.forEach((elem) => {
            if(elem.state){
                instrument.push(elem)
            }
        })

        return instrument;
    }

    function checkData() {
        if(inputSongName === ""){
            return new Error("Le titre est vide")
        } else if (selectedArtist.id === 0) {
            return new Error("Aucun artiste sélectionné")
        } else if(tunings.find((elem) => elem.state === true) === undefined) {
            return new Error("Aucun accordage sélectionné")
        } else if(tunings.filter((elem) => elem.state === true).length > 1){
            return new Error("Plusieurs accordage sélectionné")
        }else if(instruments.find((elem) => elem.state === true) === undefined) {
            return new Error("Aucun instrument sélectionné")
        } else if(styles.find((elem) => elem.state === true) === undefined) {
            return new Error("Aucun style sélectionné")
        } else if(data.songs.find((elem) => elem.title === toUpperCase(inputSongName) && elem.artist === selectedArtist.id) !== undefined){
            return new Error("Le morceau a déjà été ajouté")
        } else {
            return true
        }
    }

    function constructSongObjectForDB() {
        var pos = data.songs.findIndex(elem => elem.id === selectedId);
        var date = null
        if(pos !== -1) {
            date = data.songs[pos].lastPlayed
            if(date === undefined) {
                date = null
            }
        }

        var song = {
            title: toUpperCase(inputSongName),
            artist: selectedArtist.id,
            tuning: tunings.find((elem) => elem.state === true).id,
            instrument: getSelectedInstrument().map(elem => elem.id),
            style: styles.find((elem) => elem.state === true).id,
            lastPlayed: date
        }

        return song;
    }

    function constructSongObject() {
        var pos = data.songs.findIndex(elem => elem.id === selectedId);
        var date = undefined
        if(pos !== -1) {
            date = data.songs[pos].lastPlayed
        }

        var song = {
            title: toUpperCase(inputSongName),
            artist: selectedArtist,
            tuning: tunings.find((elem) => elem.state === true),
            instrument: getSelectedInstrument(),
            style: styles.find((elem) => elem.state === true),
            lastPlayed: date
        }

        return song;
    }

    function resetData(isDelete, id) {
        var songListTemp = data.songs
        var songCountTemp = songCount

        if(isDelete) {
            var posSongList = songListTemp.findIndex(elem => elem.id === id);

            songListTemp.splice(posSongList, 1)


            songCountTemp = songCountTemp - 1
        } if(isDelete === false && selectedId !== undefined) {
            if(id !== undefined) {
                var dbSong = constructSongObject()
    
                dbSong.id = selectedId
    
                posSongList = songListTemp.findIndex(elem => elem.id === selectedId);
    
                songListTemp[posSongList] = dbSong
            }
            resetButtonState(tunings)
            resetButtonState(instruments)
            resetButtonState(styles)
            setSelectedArtist({id: -1, name: ""})
            setSelectedId(undefined)
        } else if(isDelete === false && id === undefined){
            dbSong = constructSongObject()

            var lastId = parseInt(data.songs[0].id)
            
            dbSong.id = lastId+1

            songListTemp.unshift(dbSong)

            songCountTemp = songCountTemp + 1
        }
        setSongCount(songCountTemp)
        setInputSongName("")

        onAdd(songListTemp)
    }

    function addSong() {
        const dataValidity = checkData()
        if(dataValidity === true || selectedId !== undefined) {
            var song = constructSongObjectForDB()

            var lastId = parseInt(data.songs[0].id)

            const putSong = async () => {
                var spotifyURI = await getSpotifyURI(selectedArtist.name, inputSongName)
                if(spotifyURI !== undefined) {
                    song.spotifyURI = spotifyURI
                }
                if(selectedId === undefined) {
                    await addSongToFirestore((lastId+1).toString(), song)
                } else {
                    await addSongToFirestore(selectedId.toString(), song)
                }
                resetData(false, selectedId)
            }

            putSong()
        } else {
            alert(dataValidity.getMessage())
        }
    }

    function resetButtonState(collection) {
        collection.forEach(elem => elem.state = false)
    }

    function handleClickMultiple(i, collection, updateList) {
        const elem = [...collection]
        elem[i].state = !elem[i].state

        updateList(elem);
    }

    function handleClickSingle(i, collection, updateList) {
        const elem = collection.map((elem, pos) => {
            if (pos === i) {
                return { ...elem, state: !elem.state };
            } else {
                return { ...elem, state: false };
            }
        });
        
        updateList(elem);
    }

    function onSongChange(song) {
        setInputSongName(song)
    }

    function keyPressed(event){
        if (event.key === 'Enter' && blockEvent === false) {
            setBlockEvent(true)
            addSong()
        } else {
            setBlockEvent(false)
        }
      }

    function deleteSong(id) {
        const songDeletion = async () => {
            await findAll("songs")
            await deleteSongToFirebase(id)
            resetData(true, id)
        }
        songDeletion()
     }

    function getArtistById(id){
        return data.artists.find(elem => elem.id === id)
     }

    function updateSong(id) {
        var songListTemp = data.songs
        var posSongList = songListTemp.findIndex(elem => elem.id === id);

        var song = songListTemp[posSongList]

        resetButtonState(instruments)
        resetButtonState(styles)
        resetButtonState(tunings)

        if(selectedId === id) {
            setSelectedId(undefined)
            resetData(false)
        } else {
            setSelectedId(song.id)

            var posStyle = data.styles.findIndex(elem => elem.id === song.style.id);
            var posTuning = tunings.findIndex(elem => elem.id === song.tuning.id);
            song.instrument.forEach((inst) => {
                var pos = instruments.findIndex(elem => elem.id === inst.id)
                handleClickMultiple(pos, instruments, setInstruments)
            })

            onSongChange(song.title)
            handleArtistMenuClick(getArtistById(song.artist.id))
            handleClickSingle(posStyle, styles, setStyles)
            handleClickSingle(posTuning, tunings, setTunings)
        }
     }

    function handleArtistMenuClick(val) {
        if(val === null) {
            setSelectedArtist({id: 0, name: val})
        } else {
            setSelectedArtist(val)
        }
     }
    
     return (
        <div style={containerStyle}>
              <div style={topMenu}>
                  <img src={bigIcon} style={iconStyle} alt='Milist'/>
                  <ButtonList buttonListObject={tunings} onClick={handleClickSingle} updateList={setTunings}/>
                  <ButtonList buttonListObject={instruments} onClick={handleClickMultiple} updateList={setInstruments}/>
                  <ButtonList buttonListObject={styles} onClick={handleClickSingle} updateList={setStyles}/>
                  <div style={validateButtonStyle} onClick={() => addSong()}>{selectedId? "Update" : "Validate"}</div>
                  <SearchableDropdown
                      options={data.artists}
                      label="name"
                      id="id"
                      selectedVal={selectedArtist.name}
                      handleChange={(val => handleArtistMenuClick(val))}
                  />
                  <TextInput placeholderText="Song title" value={inputSongName} onChange={onSongChange.bind(this)}/>
                  <div style={songNumberStyle}>number of song added : {songCount}</div>
                  <hr style={lineSeparatorStyle}/>
              </div>
              <div style={contentStyle}>
                  {data.songs.length > 0 && data.songs.map((song, i) => {
                      return (<AddedSong song={song} selectedId={selectedId} onUpdate={updateSong.bind(this)} onDelete={deleteSong.bind(this)}/>) 
                  })}
              </div>
        </div>
      );
}
