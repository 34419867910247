import React from 'react';
import InstrumentImage from './instrumentImage';
import deleteIcon from '../icons/delete.png';
import update from '../icons/update.png';

const divContainer = {
  display: 'flex',
  alignItems: 'center'
}

const buttonStyle = {
  height: '40px',
  width: '40px',
  margin: '0px 20px 0px 20px',
  cursor: 'pointer'
}

const divStyle = {
  position: 'relative',
  margin: '10px 10px 0px 20px',
  padding: '0px 50px 0px 10px',
  width: '550px',
  border: '1px solid black',
  borderRadius: '10px'
}

const divStyleSelected = {
  position: 'relative',
  margin: '10px 10px 0px 20px',
  padding: '0px 50px 0px 10px',
  width: '550px',
  border: '1px solid black',
  borderRadius: '10px',
  backgroundColor: 'grey'
}

const idStyle = {
  position: 'absolute',
  display: 'inline-block',
  margin:'0px 10px 0px 0px',
  right: '0'
}

export default function AddedSong({song, selectedId, onUpdate, onDelete}) {

  function handleDelete(i) {
    const confirmation = window.confirm('Are you sure you want to delete this song ?');

    if(confirmation) {
      onDelete(i)
    }
  }

  function handleUpdate(i) {
    onUpdate(i)
  }

  return (
    <div style = {divContainer}>
      {selectedId===song.id? 
        <div style={divStyleSelected}>
          <div style={idStyle}>{song.id}</div>
          <b>{song.artist.name}</b> | {song.title}
          <br/>
          Tuning : {song.tuning.name} | {song.style.name} | {song.instrument.map(element => {
            return (<InstrumentImage id={element.id}/>)
          })}
          <br/>
        </div> 
        : 
        <div style={divStyle}>
          <div style={idStyle}>{song.id}</div>
          <b>{song.artist.name}</b> | {song.title}
          <br/>
          Tuning : {song.tuning.name} | {song.style.name} | {song.instrument.map(element => {
            return (<InstrumentImage id={element.id}/>)
          })}
          <br/>
        </div>
      }
      <img src={deleteIcon} style={buttonStyle} onClick={() => handleDelete(song.id)} alt="delete"/>
      <img src={update} style={buttonStyle} onClick={() => handleUpdate(song.id)} alt="update"/>
    </div>
  );
}