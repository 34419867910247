import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AddSongScreen from './pages/addSongScreen';
import AddArtistScreen from './pages/addArtistScreen';
import PlaylistScreen from './pages/playlistScreen';
import SpotifyRedirection from './pages/spotifyRedirection.js';
import React from 'react';

import {findAll} from './services/firestoreHelper.js'

class App extends React.Component {
  state = {
    artistList: [{id: 0, name: "No data loaded"}],
    tuningList: {},
    instrumentList: {},
    styleList: {},
    songList: {}
  }

  componentDidMount() {

    const fetchData = async () => {
      const artistsRes = await findAll("artists")
      const tuningsRes = await findAll("tuning")
      const instrumentsRes = await findAll("instruments")
      const stylesRes = await findAll("style")
      const songListRes = await findAll("songs")

      tuningsRes.forEach((elem) => elem.state = false)
      instrumentsRes.forEach((elem) => elem.state = false)
      stylesRes.forEach((elem) => elem.state = false)

      var songListTemp = []
      songListRes.forEach(element => {
        var song = {}
        var instrument = instrumentsRes.filter(instrument => element.instrument.some(selectInstrument => instrument.id === selectInstrument))
        var instrumentList = []
        instrument.map(elem => instrumentList.push(elem))

        song.id = element.id
        song.title = element.title
        song.artist = artistsRes.find((artist) => artist.id === element.artist)
        song.instrument = instrumentList
        song.style = stylesRes.find((style) => style.id === element.style);
        song.tuning = tuningsRes.find((tuning) => tuning.id === element.tuning)
        song.spotifyURI = element.spotifyURI
        song.lastPlayed = element.lastPlayed
        songListTemp.push(song)
      });

      artistsRes.sort(this.compareByName)
      songListTemp.sort(this.compareById)

      this.setState({
        artistList: artistsRes,
        tuningList: tuningsRes,
        instrumentList: instrumentsRes,
        styleList: stylesRes,
        songList: songListTemp
      })
    }
    
    fetchData()
  }

  compareById(a, b) {
    return b.id - a.id;
  }

  compareByName(a, b) {
    return a.name.localeCompare(b.name)
  }

  updateArtistList(artists) {
    this.setState({artistList: artists})
  }

  updateSongList(songs) {
    this.setState({songList: songs})
  }

  getDataForScreen() {
    return {
      artists: this.state.artistList, 
      tunings: this.state.tuningList, 
      instruments: this.state.instrumentList, 
      styles: this.state.styleList, 
      songs: this.state.songList
    }
  }

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<AddSongScreen 
            data={this.getDataForScreen()}
            onAdd={this.updateSongList.bind(this)}/>} />
          <Route path="/addArtist" element={<AddArtistScreen artists={this.state.artistList} onAdd={this.updateArtistList.bind(this)}/>} />
          <Route path="/playlist" element={<PlaylistScreen
            data={this.getDataForScreen()}/>} />
          <Route path="/spotifyRedirection" element={<SpotifyRedirection/>} />
        </Routes>
      </Router>
    );
  }
}

export default App;
