import React from 'react'

import { getTokenAuth } from '../services/spotifyAPI.js'

const args = new URLSearchParams(window.location.search);
const code = args.get('code');

if (code) {
    await getTokenAuth(code);

    const url = new URL(window.location.href);
    url.searchParams.delete("code");

    const updatedUrl = url.search ? url.href : url.href.replace('?', '').replace('%2F', '/');
    window.history.replaceState({}, document.title, updatedUrl);

    window.location.href = 'https://milist-d7023.web.app/playlist';
}

export default function SpotifyRedirection() {

    return (
        <div>
            <p>Redirecting to the website...</p>
        </div>
    );
}