import React, {useState, useEffect} from 'react'

import { updateSong } from '../services/firestoreHelper.js';
import { addSongToPlaylist, redirectToSpotifyAuthorize, clearPlaylist } from '../services/spotifyAPI.js'

import ButtonList from '../component/buttonList.js';
import PlaylistSong from '../component/playlistSong.js';
import DatePicker from "react-datepicker";

import Close from '../icons/close.png'
import Random from '../icons/random.png'
import List from '../icons/list.png'

import "react-datepicker/dist/react-datepicker.css";

const lineSeparatorStyle = {
    margin:'40px 20px 10px 20px',
    border: 'none',
    backgroundColor: 'black',
    height: '3px',
    opacity: '1'
}

const imageCloseStyle = {
    margin: '0px 0px 0px 10px',
    height: '30px'
}

const randomButtonStyle = {
    position: 'absolute',
    display: 'inline-block',
    margin:'0px 20px 0px 0px',
    right: '0',
    height: '40px'
}

const numberOFSongStyle = {
    position: 'absolute',
    display: 'inline-block',
    margin:'20px 20px 0px 0px',
    right: '0'
}

const datePickerStyle = {
    margin: '0px 0px 0px 20px',
    width: '300px'
}

const spotifyButtonContainer = {
    display: 'flex'
}

const spotifyButton = {
    right: '0',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'green',
    width: '100px',
    height: '45px',
    marginLeft: 'auto',
    marginRight: '10px',
    marginTop: '10px',
    marginBottom: '-20px',
    border: '1px solid black',
    borderRadius: '50px',
    color: 'white',
    fontSize: '14px'
}

const generateButtonStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin:'10px 20px 0px 20px',
    left: '0',
    height: '40px',
    width: '150px',
    border: '1px solid black',
    borderRadius: '10px',
}

export default function PlaylistScreen({ data }) {
    const [isRandom, setIsRandom] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [todayDate, setTodayDate] = useState(null)
    const [filteredSong, setFilteredSong] = useState({})

    const [tunings, setTunings] = useState({})
    const [instruments, setInstruments] = useState({})
    const [styles, setStyles] = useState({})

    useEffect(() => {
        if(tunings.length === undefined || instruments.length === undefined || styles === undefined || filteredSong.length === undefined) {
            const currentDate = new Date();
            currentDate.setHours(0,0,0,0);
            setTodayDate(currentDate.getTime())

            setTunings(data.tunings)
            setStyles(data.styles)
            setInstruments(data.instruments)
            setFilteredSong(data.songs)
        } else {
            refreshSongList()
        }
    },[isRandom, tunings, instruments, styles, selectedDate, data])

    function compareObject(a, b) {
        return b.id - a.id;
    }

    function handleClickMultiple(i, collection, updateList) {
        const elem = [...collection]
        elem[i].state = !elem[i].state

        updateList(elem);
    }

    function refreshSongList() {
        var listTemp = Array.from(data.songs)

        listTemp = data.songs.filter(song => {
            const tunningMatch = !tunings.some(tuning => tuning.state) || tunings.some(tuning => tuning.state && song.tuning.id === tuning.id)
            const styleMatch = !styles.some(style => style.state) || styles.some(style => style.state && song.style.id === style.id)
            const instrumentMatch = !instruments.some(instrument => instrument.state) || instruments.some(songInstrument => song.instrument.some(instrument => instrument.state && instrument.id === songInstrument.id));
            const dateMatch = selectedDate === null || song.lastPlayed <= selectedDate || song.lastPlayed === undefined || song.lastPlayed >= todayDate

            return tunningMatch && styleMatch && instrumentMatch && dateMatch;
        })

        if(isRandom) {
            listTemp = randomizeSongList(listTemp)
        } else {
            listTemp.sort(compareObject);
        }

        setFilteredSong(listTemp)
    }

    function constructSongObject(song) {

        return song = {
            title: song.title,
            artist: song.artist.id,
            tuning: song.tuning.id,
            instrument: song.instrument.map(inst => inst.id),
            style: song.style.id,
            lastPlayed: song.lastPlayed,
            spotifyURI: song.spotifyURI
        }
    }

    function handlePlayed(id) {
        const currentDate = new Date();

        const update = async () => {
            const listTemp = filteredSong

            var songPos = filteredSong.findIndex(song => song.id === id)
            var song = filteredSong[songPos]

            song.lastPlayed = currentDate.getTime()

            await updateSong(id, constructSongObject(song))

            listTemp[songPos] = song

            setFilteredSong([...listTemp])
        }

        update()
     }

    function setStartDate(date) {
        if(date !== null) {
            date.setHours(23,59,59,0);
            setSelectedDate(date.getTime())
        } else {
            setSelectedDate(null)
        }
        refreshSongList()
     }

    function randomizeSongList(list) {
        const listTemp = list
        for (let i = listTemp.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [listTemp[i], listTemp[j]] = [listTemp[j], listTemp[i]];
        }
    
        return listTemp
    }

    function generatePlaylist() {
        redirectToSpotifyAuthorize()
    }

    function sleep(milliseconds) {
        const sl = async() => {
            var start = new Date().getTime();
            for (var i = 0; i < 1e7; i++) {
                if ((new Date().getTime() - start) > milliseconds){
                    break;
                }
            }
        }
        sl()
      }

    function addSong() {

        const upload = async() => {
            await clearPlaylist()
            await sleep()

            let tempList = filteredSong.filter((elem) => elem.spotifyURI !== "" && elem.spotifyURI != null)

            for (let i = 0; i < filteredSong.length; i+=100) {
                let list = tempList.slice(i, i+100).map((elem) => elem.spotifyURI)
                await addSongToPlaylist(list)
                await sleep()
            }
        }

        upload()
        
    }

    return (
        <div>
            <div style={spotifyButtonContainer}>
                <div style={spotifyButton} onClick={() => generatePlaylist()}>Spotify Connection</div>
            </div>
            <ButtonList buttonListObject={tunings} onClick={handleClickMultiple} updateList={setTunings}/>
            <ButtonList buttonListObject={instruments} onClick={handleClickMultiple} updateList={setInstruments}/>
            <ButtonList buttonListObject={styles} onClick={handleClickMultiple} updateList={setStyles}/>
            <div style={datePickerStyle}>
                <DatePicker selected={selectedDate} onChange={(date) => setStartDate(date)} />
                <img style={imageCloseStyle} src={Close} onClick={() => setStartDate(null)} alt="close"/>
            </div>
            <div style={generateButtonStyle} onClick={() => addSong()}>Generate playlist</div>
            {isRandom?
            <img style={randomButtonStyle} src={List} onClick={() => setIsRandom(!isRandom)} alt="randomize"/>
            :
            <img style={randomButtonStyle} src={Random} onClick={() => setIsRandom(!isRandom)} alt="randomize"/>}
            <br/>
            <div style={numberOFSongStyle}>Selected song : {filteredSong.length}</div>
            <hr style={lineSeparatorStyle}/>

            {filteredSong.length > 0 && filteredSong.map((song, i) => {
                return (<PlaylistSong song={song} todayDate={todayDate} onPlayed={handlePlayed}/>)
            })}
        </div>
    );
}